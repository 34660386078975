<template>
    <generic-modal-wrapper ref="wrapper">
        <template #default="{ showModal }">
            <v-row no-gutters class="justify-end">
            <v-btn :block="!$vuetify.breakpoint.smAndUp" class="elevation-0 mx-0" color="primary" @click.native.stop="() => {showModal(); customFieldAreaIdToEdit = null}" data-cy="add-custom-field-area-button">
                <v-icon>add</v-icon>
                Lisa pindalatüüp
            </v-btn>
            </v-row>
        </template>
        <template #title>
            {{title}}
        </template>
        <template #content>
            <template v-if="customFieldAreaIdToEdit">
                <template v-if="calculationStrategy && calculationStrategy.id !== 'INITIAL'">
                    Pindala strateegia muutmine ja lihtsalt salvestamine, <strong>ei muuda hetkel defineeritud pindalasid</strong>.
                    Kui soovite ka pindalad automaatselt uuesti arvutada, siis palun vajutage "Salvesta ja arvuta uuesti" nuppu.
                    Sellisel juhul kirjutatakse kõik selle pindalatüübi pindalad üle uuesti arvutatud väärtustega.
                    Automaatselt arvutatud pindalad tuleb <strong> alati käsitsi ülekontrollida!</strong>
                </template>
                <template v-else>
                    Pindalatüübi nime muutmine ja salvestamine, <strong>ei muuda hetkel defineeritud pindalasid</strong>.
                </template>
            </template>
            <template v-else>
                Uue pindalatüübi lisamisel saab Abimasin aidata uute pindalade esialgsel arvutamisel.
                Automaatselt arvutatud pindalad <strong>võivad mõnedel juhtudel olla ka valed</strong>.
                Nende väärtused tuleb <strong> alati käsitsi ülekontrollida!</strong>
                Soovi korral on hiljem võimalik ka käivitada põllupindalade uuesti arvutamine etteantud strateegia alusel. See on ilmselt kasulik, kui tulevikus põldude pindalad muutuvad.
            </template>

            <v-form ref="form" lazy-validation v-model="valid">
                <v-row no-gutters class="justify-center mt-8">
                        <v-col class="col-12">
                            <v-select
                                :items="customAreaPrefillStrategies"
                                item-text="name"
                                v-model="calculationStrategy"
                                label="Pindalatüübi esialgsed väärtused"
                                return-object
                                data-cy="custom-area-strategy-select"
                            >
                                <template #item="{item}">
                                    <v-list-item-content style="max-width: 485px">
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle v-if="item.hint" style="overflow: auto; text-overflow: initial; white-space: initial;" v-html="item.hint"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col class="col-12">
                            <v-text-field
                                v-if="shouldShowValueField"
                                v-model="calculationValue"
                                type="number"
                                :rules="[v => !!v || 'Kohustuslik väli']"
                                :suffix="calculationValueSuffix"
                                :label="calculationValueLabel"
                                data-cy="custom-area-value-input"
                            />
                        </v-col>
                        <v-col class="col-12">
                            <v-text-field
                                v-model="areaName"
                                label="Pindalatüübi nimi"
                                :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 3 || 'Liiga lühike']"
                                data-cy="custom-area-name-input"
                            />
                        </v-col>
                </v-row>
            </v-form>
        </template>
        <template #footer="{ hideModal }">
            <v-btn color="primary" :block="!$vuetify.breakpoint.smAndUp" outlined class="mx-2" @click.native="hideModal" title="Katkesta" data-cy="field-year-info-cancel-changes-button">Katkesta</v-btn>
            <v-btn v-if="customFieldAreaIdToEdit && calculationStrategy && calculationStrategy.id !== 'INITIAL'" :block="!$vuetify.breakpoint.smAndUp" color="primary" class="mr-2" @click="() => editCustomFieldAreaAndRecalculate(hideModal)" data-cy="custom-area-general-confirm-changes-and-recalc-button">Salvesta ja arvuta uuesti</v-btn>
            <v-btn color="primary" :block="!$vuetify.breakpoint.smAndUp" @click="() => addCustomFieldArea(hideModal)" data-cy="custom-area-general-confirm-changes-button">{{ confirmButtonLabel }}</v-btn>
        </template>
    </generic-modal-wrapper>
</template>
<script>

import GenericModalWrapper from "@/components/genericModalWrapper";
import _ from "lodash";
import {http} from "@/config/http";

export default {
    props: {
        year: {
            type: Number
        }
    },
    data: () => ({
        valid: true,
        customFieldAreaIdToEdit: null,
        areaName: "",
        calculationValue: null,
        calculationStrategy: null
    }),
    components: {
        GenericModalWrapper
    },
    watch: {
        customAreaPrefillStrategies: {
            handler: function () {
                this.calculationStrategy = this.customAreaPrefillStrategies[0]
            },
            immediate: true
        },
        calculationStrategy() {
            if (!this.customFieldAreaIdToEdit) {
                this.autoFillAreaName();
                this.calculationValue = null;
            }
        },
        calculationValue() {
            if (!this.customFieldAreaIdToEdit) {
                this.autoFillAreaName();
            }
        }
    },
    computed: {
        title(){
            return this.customFieldAreaIdToEdit ? "Muuda pindalatüüpi" : "Lisa uus pindalatüüp"
        },
        confirmButtonLabel() {
            return this.customFieldAreaIdToEdit ? "Salvesta" : "Lisa pindalatüüp"
        },
        fieldYears() {
            let fieldYears = this.$store.getters.getFieldYears;
            return _.filter(fieldYears, y => y !== this.baseYear);
        },
        customAreaPrefillStrategies() {
            return [
                {id: "INITIAL", name: "Kasuta põllu praeguseid pindalasid", hint: "Pindalatüüp saab praegused põldude täispindalad. Neid saab siis käsitsi muuta vastavalt vajadusele."},
                {id: "EDGE_BUFFER", name: "Ääreala võrra vähendamine", hint: "Määra mitme meetri võrra <strong>vähendatakse põllu pindala tema välisservast</strong>. Arvutamise alusena kasutatakse põllu geograafilist kontuuri. Pindala arvutamiseks nihutatakse välisperimeetit etteantud meetrite võrra. Kasulik taimekaitsetöödel, mida tuleb teostada <strong>ilma äärealadeta</strong> pindalal."},
                {id: "EDGE_BUFFER_ONLY", name: "Ainult äärering", hint: "Määra mitme meetri laiust <strong>ääreringi, põllu välisservast</strong>, soovite arvutada. Arvutamise alusena kasutatakse põllu geograafilist kontuuri. Ääreringi pindala arvutatakse etteantud meetrite laiusel põllu välisringil. Kasulik taimekaitsetöödel, mida teostatakse <strong>ainult äärealadel.</strong>"},
                {id: "PERCENT", name: "Protsendiline muutmine", hint: "Määra protsent, mille võrra põllu praeguseid pindalasid muuta."},
            ];
        },
        shouldShowValueField() {
            return this.calculationStrategy && this.calculationStrategy.id !== "INITIAL";
        },
        calculationValueLabel() {
            if (this.calculationStrategy) {
                if (this.calculationStrategy.id === "EDGE_BUFFER") {
                    return "Ääreala laius meetrites";
                }
                if (this.calculationStrategy.id === "EDGE_BUFFER_ONLY") {
                    return "Ääreringi laius meetrites";
                }
                if (this.calculationStrategy.id === "PERCENT") {
                    if (this.calculationValue && this.calculationValue < 0) {
                        return "Suurendamise protsent";
                    }
                    return "Vähendamise protsent";
                }
            }
            return null
        },
        calculationValueSuffix() {
            if (this.calculationStrategy) {
                if (this.calculationStrategy.id === "EDGE_BUFFER" || this.calculationStrategy.id === "EDGE_BUFFER_ONLY") {
                    return "m";
                }
                if (this.calculationStrategy.id === "PERCENT") {
                    return "%";
                }
            }
            return null
        }
    },
    methods: {
        editCustomFieldAreaAndRecalculate(hideModalMethod) {
            this.addCustomFieldArea(hideModalMethod, true);
        },
        addCustomFieldArea(hideModalMethod, forceRecalculate = false) {
            if (this.$refs.form.validate()) {
                const requestPayload = {
                    year: this.year,
                    areaName: this.areaName,
                    calculationStrategy: this.calculationStrategy.id,
                    calculationValue: this.calculationValue
                };
                if (this.customFieldAreaIdToEdit) {
                    let url = `/custom-areas/${this.customFieldAreaIdToEdit}`;
                    if (forceRecalculate) {
                        url += "?forceRecalculate=true";
                    }
                    http().put(url, requestPayload).then(() => {
                        if (forceRecalculate) {
                            this.$store.dispatch('setSystemMessage', {text: "Pindalatüüp uuendatud ja uuesti arvutatud!"});
                        } else {
                            this.$store.dispatch('setSystemMessage', {text: "Pindalatüüp uuendatud!"});
                        }
                        this.areaName = "";
                        this.calculationStrategy = null;
                        this.calculationValue = null;
                        this.$emit('customFieldAreaAdded');
                        hideModalMethod();
                    }).catch(e => {
                        this.$store.dispatch('setSystemMessage', {text: "Salvestamine ebaõnnestus!", type: "ERROR"});
                        console.log(e);
                    });
                } else {
                    http().post('/custom-areas', requestPayload).then(() => {
                        this.$store.dispatch('setSystemMessage', {text: "Pindalatüüp lisatud!"});
                        this.$emit('customFieldAreaAdded');
                        hideModalMethod();
                    }).catch(e => {
                        this.$store.dispatch('setSystemMessage', {text: "Lisamine ebaõnnestus!", type: "ERROR"});
                        console.log(e);
                    });
                }

            }
        },
        show(dataToEdit) {
            this.customFieldAreaIdToEdit = dataToEdit.customFieldAreaId;
            this.areaName = dataToEdit.areaName;
            this.calculationStrategy = _.find(this.customAreaPrefillStrategies, {id: dataToEdit.calculationStrategy});
            this.calculationValue = dataToEdit.calculationValue;
            this.$refs.wrapper.triggerShowModal();
        },
        autoFillAreaName() {
            if (this.calculationStrategy) {
                let calculationValue = this.calculationValue;
                if (calculationValue === "") {
                    calculationValue = null;
                }
                const prefix = calculationValue < 0 ? "suurendus" : "vähendus";
                switch(this.calculationStrategy.id) {
                    case "EDGE_BUFFER":
                        this.areaName = (calculationValue ?? '- ') + "m ääreala";
                        break;
                    case "EDGE_BUFFER_ONLY":
                        this.areaName = (calculationValue ?? '- ') + "m äärering";
                        break;
                    case "PERCENT":
                        this.areaName = (Math.abs(calculationValue) ?? '- ') + "% " + prefix;
                        break;
                    default:
                        this.areaName = "";
                }
            }
        }
    },
}
</script>